export const independentProjectsData = [
  {
    title: "Meta Spheres",
    year: 2021,
    activelyMaintained: false,
    img_src_arr: ["./img/metaspheres.png"],
    body: "Built for my portfolio to display my passion for math, design, and 3D sites.",
    href: "https://github.com/ianrios/ianrios.github.io/tree/main/src/three",
    live: "https://ianrios.me/three",
    tools: [
      "Three.js",
      "React",
      "JavaScript",
      "React-Three-Paper",
      "React Three Fiber",
    ],
  },
  {
    title: "The Algorithm Knows Best",
    year: 2021,
    activelyMaintained: false,
    img_src_arr: ["./img/takb.png"],
    body: "An reverse-entropy based algorithm for optimizing song order for playlists. Built within a self-contained streaming platform on a single album of music meant to be played on shuffle. Showcased as an interactive installation at the Studio 300 Music and Art Conference.",
    href: "https://github.com/ianrios/the-algo-knows-best",
    live: "https://the-algorithm-knows-best.web.app",
    tools: [
      "React",
      "JavaScript",
      "Bootstrap",
      "Laravel",
      "PHP",
      "Firebase",
      "React Router",
      "Google Cloud",
    ],
  },
  {
    title: "WHY? Record Company Website",
    year: 2019,
    activelyMaintained: true,
    img_src_arr: ["./img/wrc_site.png"],
    body: "Site built for my record label to assist our artists. Includes an artist portal, store, and information visualization for musicians and listeners.",
    href: "https://github.com/ianrios/WRC",
    live: "https://whyrecord.com/",
    tools: [
      "D3",
      "React",
      "JavaScript",
      "Firebase",
      "Bootstrap",
      "React Router",
      "Lodash",
      "React-Helmet",
      "React-Virtualized",
      "styled-components",
    ],
  },
  //   {
  //     title: "Top-5",
  //     img_src_arr: ["./img/top-5.png"],
  //     body: "A simple kanban-based todo list that allows for recurring tasks (daily, weekly, and more), drag and drop functionality, and priority tags. Good for procrastinators.",
  //     href: "https://github.com/ianrios/top-5",
  //     live: "",
  //   },
  //   {
  //     title: "Budget App",
  //     img_src_arr: [],
  //     body: "A financial forecaster for individuals and families. Built using React and Laravel.",
  //     href: "https://github.com/ianrios/budget-api",
  //     live: "https://github.com/ianrios/budget-app",
  //   },
  {
    title: "Twitterbot",
    year: 2018,
    activelyMaintained: false,
    img_src_arr: [],
    body: "Allows users to add multiple accounts to harvest likes and followers, view retention statistics, and make changes to algorithm metrics in real time. It scrapes for users to follow based on retention, interests, likes, and more. Includes parameters for following and unfollowing users across time to maintain following-to-follower ratios.",
    href: "https://github.com/ianrios/twitterbot",
    live: "",
    tools: ["React", "Bootstrap", "JavaScript", "Python", "Twitter API"],
  },
  //   {
  //     title: "Twitterbot API",
  //     img_src_arr: [],
  //     href: "https://github.com/ianrios/twitterbot",
  //     live: "",
  //   },
  // {
  //     title: "PLant lOG",
  //     img_src_arr: ["./",],
  //     body: "",
  //     href: "https://github.com/ianrios/plog",
  //     live: ""
  // },
  {
    title: "neThing",
    year: 2018,
    activelyMaintained: false,
    tools: ["Node.js", "Bootstrap", "Pure Data"],
    img_src_arr: ["./img/interface_JS_42218.png"],
    body: "An Educational Open-Source Web-based Graphical User Interface for Controlling Ambisonic and Wave Field Speaker Arrays.",
    href: "https://github.com/ianrios/neThing",
    live: "",
  },
  {
    title: "Portfolio",
    year: 2017,
    activelyMaintained: true,
    img_src_arr: ["./img/portfolio.png"],
    body: "Interactive React Portfolio.",
    href: "https://github.com/ianrios/ianrios.github.io",
    live: "https://ianrios.me",
    tools: [
      "React",
      "JavaScript",
      "Firebase",
      "React Router",
      "ChatGPT",
      "Bootstrap",
    ],
  },
  {
    title: "Borilliant",
    year: 2017,
    activelyMaintained: false,
    tools: ["Angular", "Heroku", "MongoDB", "Express"],
    img_src_arr: ["./img/borilliant_fb.png"],
    body: "A student-teacher rating system similar to Rate My Professor built using the MEAN stack.",
    href: "https://github.com/bobmayuze/Borilliant_Feedback",
    live: "",
  },
  {
    title: "Barely Enough Ingredients - Many Online Meals",
    year: 2017,
    activelyMaintained: false,
    tools: ["Heroku", "Ruby", "Rails", "Bootstrap", "PostgreSQL", "Bootstrap"],
    img_src_arr: ["./img/momsearch.png"],
    body: "The Many Online Meals (MOM) app is a Ruby On Rails web app that utilizes Bootstrap for styling, the Spoonacular API for recipe and ingredient data, and Heroku for hosting.",
    href: "https://github.com/ianrios/BarelyEnoughIngredients/tree/master",
    live: "https://bei-mom.herokuapp.com/",
  },
  {
    title: "Grandulator",
    year: 2017,
    activelyMaintained: false,
    tools: ["Max MSP"],
    img_src_arr: ["./img/grandulator.png", "./img/controls.png"],
    body: "Ensemble performance instrument that allows for real time autotuning of live granular synthesis.",
    href: "https://github.com/ianrios/Grandulator",
    live: "",
  },
  {
    title: "RAAPT V1",
    year: 2017,
    activelyMaintained: false,
    tools: ["Max MSP"],
    img_src_arr: ["./img/V1.png"],
    body: "The RAAPT project generates rhythmic musical structures by assigning pre-made templates to ecological soundscapes. By applying structural templates to audio clips, RAAPT creates an automated and generative music system capable of transforming any sonic material into rhythmic, musical instruments.",
    href: "https://github.com/ianrios/RAAPT",
    live: "",
  },
];

export const workProjectsData = [
  {
    title: "BAFConX",
    year: 2023,
    tools: [
      "Flutter",
      "Dart",
      "Material Design",
      "Redux",
      "Python",
      "Protocol Buffers",
      "Lexmark Optra",
      "PostgreSQL",
      "Figma",
      "Docker",
      "Swagger",
      "GitHub Actions",
    ],
    activelyMaintained: true,
    company: "Big Ass Fans",
    img_src_arr: ["./gif/bafconx.gif"],
    body: "Automated climate-control system similar to Google Nest. Built to work at an industrial scale with a variety of LoRa WAN sensors to optimize performance.",
    href: "",
    live: "",
  },
  {
    title: "Funnel",
    year: 2023,
    tools: [
      "React",
      "TypeScript",
      "Material Design",
      "Google Apps Script",
      "AWS Amplify",
      "AWS S3",
      "Konva.js",
      "D3",
      "React Router",
      "styled-components",
      "GitHub Actions",
    ],
    activelyMaintained: false,
    company: "Big Ass Fans",
    img_src_arr: ["./gif/funnel.gif"],
    body: "Project Timeline Management Tool - built to view current, past, and future project states to efficiently showcase team status to management.",
    href: "",
    live: "",
  },
  {
    title: "Sales Toolbox",
    year: 2023,
    tools: [
      "React",
      "TypeScript",
      "Hookstate",
      "PostgreSQL",
      "Nest.js",
      "AWS Elastic Beanstalk",
      "AWS CodeBuild",
      "AWS RDS",
      "AWS EC2",
      "pnpm",
      "webpack",
      "Compodoc",
      "Fastify",
      "Swagger",
      "react-pdf",
      "Netlify",
      "Axios",
      "Auth0",
      "TypeORM",
      "D3",
      "Material Design",
      "Lodash",
      "radash",
      "PapaParse",
      "SendGrid",
      "GitHub Actions",
    ],
    activelyMaintained: true,
    company: "Big Ass Fans",
    img_src_arr: ["./img/sales-toolbox.png"],
    body: "Kit of mobile-friendly web apps used by internal sales team to show value prop quickly when generating quotes for customers while performing a site visit.",
    href: "",
    live: "https://learn.bigassfans.com",
  },
  {
    title: "SpecLab",
    year: 2022,
    tools: [
      "Three.js",
      "React",
      "PostgreSQL",
      "JavaScript",
      "React Router",
      "Redux",
      "Blender",
      "Storybook",
      "Sentry",
      "Docker",
      "Apollo",
      "Lodash",
      "styled-components",
      "SendGrid",
      "Netlify",
      "AWS CodeBuild",
      "AWS Elastic Beanstalk",
      "Salesforce",
      "AWS EC2",
      "AWS RDS",
      "GraphQL",
      "Cloudinary",
      "Sequelize",
      "Axios",
      "GitHub Actions",
      "Salesforce",
      "Auth0",
    ],
    activelyMaintained: true,
    company: "Big Ass Fans",
    img_src_arr: ["./gif/speclab.gif"],
    body: "Computational Fluid Dynamics (CFD) Analysis & Simulation tool",
    href: "https://github.com/ianrios/ianrios.github.io/tree/main/src/three",
    live: "https://bigassfans.com/speclab",
  },
  {
    title: "Virtual Showroom & Fan Selector",
    year: 2022,
    tools: [
      "React",
      "TypeScript",
      "Material Design",
      "3DVista",
      "Netlify",
      "Hookstate",
    ],
    activelyMaintained: false,
    company: "Big Ass Fans",
    img_src_arr: ["./gif/virtual-showroom.gif"],
    body: "An Augmented Reality Web App for comparing products in your space. Includes a product size selector tool and a 3D panorama viewer.",
    href: "",
    live: "https://bigassfans.com/virtual-showroom",
  },
  {
    title: "React Educational Curriculum",
    year: 2021,
    tools: ["React", "JavaScript", "React Router"],
    activelyMaintained: false,
    company: "Awesome Inc",
    img_src_arr: ["./img/youtube.png"],
    body: "This curriculum guides new-to-React coders through basics like state and lifecycle, intermediate concepts like Sass and external packages, and advanced topics including custom hook creation, using the context API, and defining new reducers.",
    href: "",
    live: "https://www.youtube.com/playlist?list=PLueGNSATcrZtoNBo4qXcKlj1ykQos7e0I",
  },
  {
    title: "TALES Admin",
    year: 2022,
    tools: [
      "React",
      "JavaScript",
      "React Router",
      "PostgreSQL",
      "Redux",
      "Netlify",
      "Storybook",
      "Sentry",
      "Apollo",
      "Lodash",
      "styled-components",
      "Auth0",
      "SendGrid",
      "AWS CodeBuild",
      "AWS Elastic Beanstalk",
      "AWS EC2",
      "AWS RDS",
      "GraphQL",
      "Salesforce",
      "Auth0",
      "Cloudinary",
      "Express",
      "PapaParse",
      "Sequelize",
      "GitHub Actions",
    ],
    activelyMaintained: false,
    company: "Big Ass Fans",

    body: "Admin dashboard to manage running CFD sims, user permissions, export metrics, and view database entities",
    href: "https://github.com/ianrios/ianrios.github.io/tree/main/src/three",
    live: "https://bigassfans.com/speclab",
  },
  {
    title: "SalesHub",
    year: 2022,
    tools: [
      "React",
      "JavaScript",
      "PostgreSQL",
      "React Router",
      "Redux",
      "Sequelize",
      "Auth0",
      "Netlify",
      "Sentry",
      "Apollo",
      "Lodash",
      "styled-components",
      "SendGrid",
      "Storybook",
      "AWS CodeBuild",
      "AWS Elastic Beanstalk",
      "AWS EC2",
      "AWS RDS",
      "GraphQL",
      "Salesforce",
      "Cloudinary",
      "GitHub Actions",
    ],
    activelyMaintained: false,
    company: "Big Ass Fans",

    body: "Salesforce Abstraction Layer tool to assist with attaching an opportunity to a SpecLab drawing.",
    href: "https://github.com/ianrios/ianrios.github.io/tree/main/src/three",
    live: "https://bigassfans.com/speclab",
  },
  {
    title: "Lightbot",
    year: 2020,
    tools: ["React", "JavaScript", "Konva.js", "Firebase", "Bootstrap"],
    activelyMaintained: false,
    company: "Awesome Inc",
    img_src_arr: ["./img/lightbot.png"],
    body: "An interviewing tool for inexperienced potential developers, to assess their computer literacy, logical thinking, and problem solving skills.",
    href: "",
    live: "https://lightbot-1fb5d.web.app/",
  },
  {
    title: "Product Consignment Layout",
    year: 2020,
    tools: [
      "React",
      "Ant Design",
      "AWS Lambda",
      "PostgreSQL",
      "Lodash",
      "AWS DynamoDB",
      "Salesforce",
      "React-Virtualized",
    ],
    activelyMaintained: false,
    company: "Luxury Garage Sale",
    body: "An e-commerce site to facilitate direct to consumer high end fashion consignment. Worked on a product sort and filter page, a serverless upload product user flow, and a mobile-friendly infinite scroll.",
    href: "",
    live: "https://luxurygaragesale.com",
  },
  {
    title: "Forecastr",
    year: 2019,
    tools: [
      "React",
      "JavaScript",
      "Bootstrap",
      "PostgreSQL",
      "Lodash",
      "ramda",
      "Python",
      "Django",
    ],
    activelyMaintained: false,
    company: "Apax Software",
    img_src_arr: ["./gif/forecastr.gif"],
    body: "A financial forecasting tool for small business and startups to view their available runway and budget accordingly.",
    href: "",
    live: "https://www.forecastr.co",
  },
  {
    title: "System Toolkit",
    year: 2018,
    tools: ["Python"],
    activelyMaintained: false,
    company: "Conversant",
    body: "A variety of CRON jobs, python scripts and multithreaded, object-oriented programs, used to generate reports, toggle BIOS settings, and migrate databases across a global network of 'Ad Stack' distributed system servers ",
    href: "",
    live: "",
  },
  {
    title: "Score Reader",
    year: 2017,
    tools: ["JavaScript", "Processing.js", "Max MSP"],
    activelyMaintained: false,
    company: "Jamboxx",
    img_src_arr: ["./img/score_reader.png"],
    body: "Built for Jamboxx, the score reader Max for Live patch allows a user to play the Jamboxx in time with a metronome, and view the current note to play as a highlighted color based on current song location and placement in relation to the current MIDI transport.",
    href: "",
    live: "",
  },
  {
    title: "Falling Notes",
    year: 2017,
    tools: ["JavaScript", "Processing.js"],
    activelyMaintained: false,
    company: "Jamboxx",
    img_src_arr: ["./img/fallingnotes.png"],
    body: "Built for Jamboxx, the falling notes Max for Live patch path allows a user to play the Jamboxx in time with a metronome, and view the current note to play as a highlighted color based on current song location and placement in relation to the current MIDI transport.",
    href: "",
    live: "",
  },
  {
    title: "MIDI Receive UDP",
    year: 2017,
    tools: ["Max MSP"],
    activelyMaintained: false,
    company: "RPI CCCC",
    img_src_arr: ["./img/midireceive.png"],
    body: "Built for RPI CCCC, the MIDI Receive UDP Max for Live Patch allows users to sync their MIDI transports over a network connection, by introducing latency and connecting start and stop actions via Ableton Link.",
    href: "",
    live: "",
  },
  {
    title: "MIDI Send UDP",
    year: 2017,
    tools: ["Max MSP"],
    activelyMaintained: false,
    company: "RPI CCCC",
    img_src_arr: ["./img/midisend.png"],
    body: "Built for RPI CCCC, the MIDI Send UDP Max for Live Patch allows users to sync their MIDI transports over a network connection, by introducing latency and connecting start and stop actions via Ableton Link.",
    href: "",
    live: "",
  },
];

export const hobbyData = [
  {
    title: "We're Eating Good Tonight",
    year: 2021,
    activelyMaintained: false,
    img_src_arr: ["./img/wegt2.png"],
    body: "I was able to turn one of my favorite hobbies that I get to do every day - cooking delicious food and giving others joy in tasting it, into a profitable business. I worked as a personal chef meal prepping lunches for my co-workers. Learning business management skills, budgeting, and food safety was a challenge while balancing my full time software career and trying to make local industry connections, but I still was able to deliver inexpensive, high-quality, nourishing food for my clients. When I'm not doing that, I am constantly experimenting in the kitchen for at-home meals.",
    url: "",
    instagram: "https://www.instagram.com/wereeatinggoodtonight/",
  },
  {
    title: "WRC Label Manager",
    year: 2019,
    activelyMaintained: true,
    img_src_arr: ["./img/wrc_site.png"],
    body: "My most complex hobby - an independently owned and operated record label, featuring some of my favorite artists and friends from around the globe. I've utilized a majority of my software background to help the label achieve success by creating automation tools for logistics and social media.",
    url: "https://whyrecord.com",
    instagram: "https://www.instagram.com/whyrecordcompany/",
  },
  {
    title: "Music Production",
    year: 2015,
    activelyMaintained: true,
    img_src_arr: ["./img/profile.JPG"],
    body: 'If you\'ve gotten this far, you already know that I love creating pretty much everything. "whythough?" and "Wash And Fold Laundry Service" are just a few of the many artist personas I have developed based on a focused genre or sound. Although this persona is focused on experimental electronic music, I enjoy producing most genres of electronic music, as well as DJing all sorts of music.',
    url: "https://whyrecord.com/artist/wash-and-fold-laundry-service/",
    instagram: "https://www.instagram.com/whythough000",
  },
];

export const tools = {
  React: "https://react.dev/",
  JavaScript: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  "React Router": "https://reactrouter.com/en/main",
  Lodash: "https://lodash.com/",
  "GitHub Actions": "https://github.com/features/actions",
  Bootstrap: "https://getbootstrap.com/",
  "Material Design": "https://m3.material.io/",
  Redux: "https://redux.js.org/",
  "AWS Elastic Beanstalk": "https://aws.amazon.com/elasticbeanstalk/",
  "AWS CodeBuild": "https://aws.amazon.com/codebuild/",
  "AWS RDS": "https://aws.amazon.com/RDS/",
  "AWS EC2": "https://aws.amazon.com/EC2/",
  "AWS S3": "https://aws.amazon.com/S3/",
  "AWS DynamoDB": "https://aws.amazon.com/dynamodb/",
  "AWS Lambda": "https://aws.amazon.com/lambda/",
  "AWS Amplify": "https://aws.amazon.com/amplify/",
  SendGrid: "https://sendgrid.com/",
  Netlify: "https://www.netlify.com/",
  "styled-components": "https://styled-components.com/",
  Python: "https://www.python.org/",
  TypeScript: "https://www.typescriptlang.org/",
  pnpm: "https://pnpm.io/",
  D3: "https://d3js.org/",
  Storybook: "https://Storybook.js.org/",
  Sentry: "https://sentry.io/welcome/",
  Apollo: "https://www.apollographql.com/",
  GraphQL: "https://graphql.org/",
  Salesforce: "https://developer.salesforce.com/docs/apis",
  "Lexmark Optra":
    "https://www.lexmark.com/en_us/solutions/optra-iot-solutions.html",
  Figma: "https://www.figma.com/",
  "Three.js": "https://threejs.org/",
  "React Three Fiber":
    "https://docs.pmnd.rs/react-three-fiber/getting-started/introduction",
  "3DVista": "https://www.3dvista.com/en/",
  Cloudinary: "https://cloudinary.com/",
  "Konva.js": "https://konvajs.org/index.html",
  Firebase: "https://firebase.google.com/",
  ChatGPT: "https://chat.openai.com/",
  "Twitter API": "https://developer.twitter.com/en/docs/twitter-api",
  "React-Helmet": "https://www.npmjs.com/package/react-helmet",
  Docker: "https://www.docker.com/",
  "React-Virtualized": "http://bvaughn.github.io/react-virtualized",
  "Google Cloud": "https://cloud.google.com/",
  PapaParse: "https://www.npmjs.com/package/papaparse",
  Sequelize: "https://sequelize.org/",
  ramda: "https://ramdajs.com/",
  PHP: "https://www.php.net/",
  Hookstate: "https://hookstate.js.org/",
  Flutter: "https://flutter.dev/",
  Dart: "https://dart.dev/",
  "Protocol Buffers": "https://protobuf.dev/",
  "Google Apps Script": "https://www.google.com/script/start/",
  "Nest.js": "https://nestjs.com/",
  "react-pdf": "https://react-pdf.org/",
  radash: "https://radash-docs.vercel.app/docs/getting-started",
  Laravel: "https://laravel.com/",
  webpack: "https://webpack.js.org/",
  Axios: "https://axios-http.com/docs/intro",
  Compodoc: "https://compodoc.app/",
  Swagger: "https://swagger.io/",
  Fastify: "https://fastify.dev/",
  TypeORM: "https://typeorm.io/",
  "Ant Design": "https://ant.design/",
  Express: "https://expressjs.com/",
  Django: "https://www.djangoproject.com/",
  Auth0: "https://auth0.com/",
  Blender: "https://www.blender.org/",
  "Max MSP": "https://cycling74.com/products/max",
  "React-Three-Paper": "https://github.com/FarazzShaikh/react-three-paper",
  "Processing.js": "https://happycoding.io/tutorials/processing/processing-js",
  MongoDB: "https://www.mongodb.com/",
  Angular: "https://angular.io/",
  "Pure Data": "https://puredata.info/",
  Heroku: "https://www.heroku.com/",
  Ruby: "https://www.ruby-lang.org/en/",
  Rails: "https://rubyonrails.org/",
  "Node.js": "https://nodejs.org/en",
  PostgreSQL: "https://www.postgresql.org/",
};
